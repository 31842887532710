import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
const DisplayPosition = ({ map, data }: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [position, setPosition] = useState(() => map.getCenter());

  const onMove = useCallback(() => {
    const data = map.getCenter();
    const { lat, lng } = data;
    const newUrl = `${
      window.location.pathname
    }?lat=${lat}&long=${lng}&z=${map.getZoom()}`;
    window.history.pushState({}, "", newUrl);
    setPosition(data);
  }, [map]);

  const fetchMapView = useCallback(async () => {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      const lat = queryParams.get("lat"); // "65.3564599940997"
      const lng = queryParams.get("long"); // "-87.75878906250001"
      const zoom = queryParams.get("z"); // "5"
      map.setView({ lat, lng }, zoom);
    } catch (error) {
      console.error("Failed to fetch map view:", error);
    }
  }, [map]);
  useEffect(() => {
    setTimeout(() => {
      fetchMapView();
    }, 500);
  }, [fetchMapView]);

  useEffect(() => {
    map.on("move", onMove);
    return () => {
      map.off("move", onMove);
    };
  }, [map, onMove]);

  return (
    <>
      {/* <p
        style={{
          position: "absolute",
          top: 0,
          zIndex: 999,
          textAlign: "center",
          width: "100%",
        }}
      >
        latitude: {position.lat.toFixed(4)}, longitude:{" "}
        {position.lng.toFixed(4)}{" "}
        <button
          onClick={() => {
            // setPosition(position);
            // map.setView(position, 2);
            map.setView({ lat: 56.5443, lng: -101.25 }, 11);
          }}
        >
          reset
        </button>
      </p> */}
    </>
  );
};

export default DisplayPosition;
