import SelectDistrict from "./components/SelectDistrict";

function LoadingScreen() {
  return (
    <>
      <SelectDistrict />
    </>
  );
}

export default LoadingScreen;
