import { useMemo, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { useParams } from "react-router-dom";
import { Jwt } from "./jwt";
import { configDataDistrict } from "./data/config";
import LoadingScreen from "./LoadingScreen";
import SelectDistrict from "./components/SelectDistrict";
import DisplayPosition from "./DisplayPosition";
const schemeT = "a/{z}/images/{x}/{y}.jpg";

function MyMap() {
  const [isShow, setIsShow] = useState(false);
  const [isFree, setIsFree] = useState(process.env.REACT_APP_STRICT !== "true");
  const [map, setMap] = useState(null);
  let { location } = useParams();

  const genToken = async (payload: any) => {
    new Jwt().generateTokenWithExpiry(payload, "1m");
  };

  const data = useMemo(() => {
    if (!location) {
      return null;
    }
    const dataCurrent = configDataDistrict[location];
    genToken({
      ...dataCurrent,
      scheme: dataCurrent?.scheme || dataCurrent?.path + schemeT,
    });

    setTimeout(() => {
      setIsShow(true);
    }, 300);

    return dataCurrent;
  }, [location]);

  const displayMap = useMemo(
    () => {
      if (data) {
        return (
          <MapContainer
            style={{ height: "100vh", width: "100%" }}
            center={{ lat: 0, lng: 0 }}
            zoom={data.defaultZoom || 2}
            scrollWheelZoom={true}
            ref={setMap as any}
          >
            {isFree ? (
              <TileLayer
                attribution='<a href="https://www.openstreetmap.org/copyright">Thichdan.com</a> contributors'
                url={`${process.env.REACT_APP_PATH}/imgxxx/${
                  data.path
                }/{z}/{x}_{y}.${data.ext || "jpeg"}`}
                tileSize={data.tileSize}
                zoomOffset={data.zoomOffset}
                // minNativeZoom={data.minZoom}
                maxNativeZoom={data.maxZoom}
                maxZoom={16}
                minZoom={data.minZoom}
              />
            ) : (
              <TileLayer
                attribution='<a href="https://www.openstreetmap.org/copyright">Thichdan.com</a> contributors'
                url={`${process.env.REACT_APP_PATH}/img/${
                  data.scheme || data.path + schemeT
                }`}
                // tileSize={data.tileSize}
                zoomOffset={data.zoomOffset}
                // minNativeZoom={data.minZoom}
                maxNativeZoom={data.maxZoom}
                maxZoom={16}
                minZoom={data.minZoom}
              />
            )}
          </MapContainer>
        );
      } else {
        return null;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]
  );
  if (!isShow) {
    return (
      <h1>
        <LoadingScreen />
      </h1>
    );
  }
  return (
    <div>
      <SelectDistrict />
      {map ? <DisplayPosition data={configDataDistrict} map={map} /> : null}
      {displayMap}
    </div>
  );
}

export default MyMap;
