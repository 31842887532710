import { Select } from "antd";
import { useMemo } from "react";
import { configDataDistrict } from "../data/config";
import { useNavigate, useParams } from "react-router-dom";

const quanName = {
  binhtan: "Bình Tân",
  binhthanh: "Bình Thạnh",
  phunhuan: "Phú Nhuận",
  q1: "Quận 1",
  q2: "Quận 2",
  q3: "Quận 3",
  q4: "Quận 4",
  q5: "Quận 5",
  q6: "Quận 6",
  q7: "Quận 7",
  q8: "Quận 8",
  q9: "Quận 9",
  q10: "Quận 10",
  q11: "Quận 11",
  q12: "Quận 12",
  tanbinh: "Tân Bình",
  thuduc: "Thủ Đức",
  quan1: "Quận 1",
  quan2: "Quận 2",
  quan3: "Quận 3",
  quan4: "Quận 4",
  quan5: "Quận 5",
  quan6: "Quận 6",
  quan7: "Quận 7",
  quan8: "Quận 8",
  quan9: "Quận 9",
  quan10: "Quận 10",
  quan11: "Quận 11",
  quan12: "Quận 12",
  govap: "Gò Vấp",
  tanphu: "Tân Phú",
} as any;

const phuongName = {
  binhtridonga: "Bình Trị Đông A",
  phuthohoa: "Phú Thọ Hòa",
  tanthanh: "Tân Thạnh",
  hieptan: "Hiệp Tân",
  hoathanh: "Hòa Thạnh",
  phuthanh: "Phú Thạnh",
  phutrung: "Phú Trung",
  sonky: "Sơn Kỳ",
  tanquy: "Tân Quý",
  tansonnhi: "Tân Sơn Nhì",
  hiephu: "Hiệp Phú",
  pvothisau: "Phường Võ Thị Sáu",
  p1: "Phường 1",
  p2: "Phường 2",
  p3: "Phường 3",
  p4: "Phường 4",
  p5: "Phường 5",
  p6: "Phường 6",
  p7: "Phường 7",
  p8: "Phường 8",
  p9: "Phường 9",
  p10: "Phường 10",
  p11: "Phường 11",
  p12: "Phường 12",
  p13: "Phường 13",
  p14: "Phường 14",
  p15: "Phường 15",
  p16: "Phường 16",
  p17: "Phường 17",
  p18: "Phường 18",
  p19: "Phường 19",
  p20: "Phường 20",
  p21: "Phường 21",
  p22: "Phường 22",
  p23: "Phường 23",
  p24: "Phường 24",
  p25: "Phường 25",
  p26: "Phường 26",
  p27: "Phường 27",
  p28: "Phường 28",
  p29: "Phường 29",
  p30: "Phường 30",

  anphudong: "An Phú Đông",
  anphutay: "An Phú Tây",
  binhtrungdong: "Bình Trưng Đông",
  binhtrungtay: "Bình Trưng Tây",
  binhtrung: "Bình Trưng",
  catlai: "Cát Lái",
  hiepthanh: "Hiệp Thành",
  hiepphu: "Hiệp Phú",
  linhchieu: "Linh Chiểu",
  linhdong: "Linh Đông",
  linhkieu: "Linh Kiểu",
  linhtruong: "Linh Trường",
  longtruong: "Long Trường",
  longbinh: "Long Bình",
  phuoclonga: "Phước Long A",
  phuoclongb: "Phước Long B",
  tamphu: "Tam Phú",
  thanhxuan: "Thạnh Xuân",
  thuanan: "Thuận An",
  trungmytay: "Trung Mỹ Tây",
  trungmydong: "Trung Mỹ Đông",
  trungmy: "Trung Mỹ",
  trungmybac: "Trung Mỹ Bắc",
  trungmynam: "Trung Mỹ Nam",
} as any;

const parsePathToString = (path: string) => {
  const arrays = path.split("_");
  let result = "";

  if (arrays[1]) {
    result += phuongName[arrays[1]] || arrays[1] + "  ";
  }
  if (arrays[0]) {
    result = `${result} ${quanName[arrays[0]] || arrays[0]}`;
  }

  return result;
};

export const SelectDistrict = () => {
  const navigate = useNavigate();
  let { location } = useParams();
  const arrayDistricts = useMemo(() => {
    return Object.keys(configDataDistrict).map((key) => {
      return configDataDistrict[key];
    });
  }, []);

  const fuzzyMatch = (label: string, search: string) => {
    const searchLower = search.toLowerCase();
    const labelLower = label.toLowerCase();
    let searchIndex = 0;
    for (
      let i = 0;
      i < labelLower.length && searchIndex < searchLower.length;
      i++
    ) {
      if (labelLower[i] === searchLower[searchIndex]) {
        searchIndex++;
      }
    }
    return searchIndex === searchLower.length;
  };

  return (
    <div className="selectDistrict">
      <Select
        showSearch
        dropdownAlign={{ offset: [0, 20] }}
        defaultValue={location}
        style={{
          width: "95%",
          marginTop: 10,
          // paddingLeft: 20,
          // paddingRight: 20,
        }}
        onSelect={(value) => {
          navigate(`/${value}`);
        }}
        placeholder="Tìm Phường Quận"
        optionFilterProp="label"
        filterSort={(optionA, optionB, { searchValue }) => {
          const matchA = fuzzyMatch(optionA?.label ?? "", searchValue);
          const matchB = fuzzyMatch(optionB?.label ?? "", searchValue);

          if (matchA && !matchB) {
            return -1;
          } else if (!matchA && matchB) {
            return 1;
          } else {
            // If both match or don't match, fall back to alphabetical sorting
            return (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase());
          }
        }}
        options={arrayDistricts.map((item) => {
          return { value: item.path, label: parsePathToString(item.path) };
        })}
      />
    </div>
  );
};

export default SelectDistrict;
