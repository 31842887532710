import * as jose from "jose";
import { convertToMilliseconds } from "./helper";
const SECRET = process.env.REACT_APP_PUBLIC_KEY || "your_secret_key";
const JWT_EXPIRY = "5m";

function setCookie(value: string, days: number) {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = "_ga_la" + "=" + value + ";" + expires + ";path=/";
}
export class Jwt {
  async generateToken(payload: any) {
    try {
      const secret = new TextEncoder().encode(SECRET);
      const jwt = await new jose.SignJWT(payload)
        .setProtectedHeader({ alg: "HS256" })
        .setExpirationTime(new Date().getTime() + parseInt(JWT_EXPIRY))
        .sign(secret);
      setCookie(jwt, 100);
      console.log("🚀 ~ Jwt ~ generateToken ~ jwt:", payload);

      return jwt;
    } catch (error) {
      throw error;
    }
  }
  async generateTokenWithExpiry(payload: any, expiry: any) {
    try {
      const expiryInMilliseconds = convertToMilliseconds(expiry);
      const secret = new TextEncoder().encode(SECRET);
      const jwt = await new jose.SignJWT(payload)
        .setProtectedHeader({ alg: "HS256" })
        .setExpirationTime(new Date().getTime() + expiryInMilliseconds)
        .sign(secret);

      setCookie(jwt, 100);
      return jwt;
    } catch (error) {
      throw error;
    }
  }
  async generateTokenWithExpiryAndNewSecret(
    payload: any,
    expiry: any,
    newSecret: any
  ) {
    try {
      const secret = new TextEncoder().encode(newSecret);
      const jwt = await new jose.SignJWT(payload)
        .setProtectedHeader({ alg: "HS256" })
        .setExpirationTime(new Date().getTime() + expiry)
        .sign(secret);
      return jwt;
    } catch (error) {
      throw error;
    }
  }
  async decodeToken(token: any) {
    try {
      const jwt = jose.decodeJwt(token);
      return jwt;
    } catch (error) {
      throw error;
    }
  }
  async verifyToken(token: string) {
    try {
      const secret = new TextEncoder().encode(SECRET);
      return await jose.jwtVerify(token, secret);
    } catch (error) {
      throw error;
    }
  }
}
