export const configDataDistrict: {
  [key: string]: {
    path: string;
    maxZoom: number;
    minZoom: number;
    tileSize: number;
    zoomOffset: number;
    ext?: string;
    scheme?: string;
    defaultZoom?: number;
  };
} = {
  quan3_p1: {
    path: "quan3_p1",
    defaultZoom: 2,
    maxZoom: 5,
    minZoom: 2,
    tileSize: 60,
    zoomOffset: 10,
  },
  quan3_p2: {
    path: "quan3_p2",
    defaultZoom: 2,
    maxZoom: 5,
    minZoom: 2,
    tileSize: 60,
    zoomOffset: 10,
  },
  quan3_p3: {
    path: "quan3_p3",
    defaultZoom: 2,
    maxZoom: 5,
    minZoom: 2,
    tileSize: 60,
    zoomOffset: 10,
  },
  quan3_p4: {
    path: "quan3_p4",
    defaultZoom: 2,
    maxZoom: 5,
    minZoom: 2,
    tileSize: 60,
    zoomOffset: 10,
  },
  quan3_p5: {
    path: "quan3_p5",
    defaultZoom: 2,
    maxZoom: 5,
    minZoom: 2,
    tileSize: 60,
    zoomOffset: 10,
  },
  quan3_p9: {
    path: "quan3_p9",
    defaultZoom: 2,
    maxZoom: 5,
    minZoom: 2,
    tileSize: 60,
    zoomOffset: 10,
  },
  quan3_p10: {
    path: "quan3_p10",
    defaultZoom: 2,
    maxZoom: 5,
    minZoom: 2,
    tileSize: 60,
    zoomOffset: 10,
  },
  quan3_p11: {
    path: "quan3_p11",
    defaultZoom: 2,
    maxZoom: 5,
    minZoom: 2,
    tileSize: 60,
    zoomOffset: 10,
  },
  quan3_p12: {
    path: "quan3_p12",
    defaultZoom: 2,
    maxZoom: 5,
    minZoom: 2,
    tileSize: 60,
    zoomOffset: 10,
  },
  quan3_p13: {
    path: "quan3_p13",
    defaultZoom: 2,
    maxZoom: 5,
    minZoom: 2,
    tileSize: 60,
    zoomOffset: 10,
  },
  quan3_pvothisau: {
    path: "quan3_pvothisau",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 2,
    tileSize: 60,
    zoomOffset: 10,
  },

  binhthanh_p1: {
    path: "binhthanh_p1",
    scheme: "binhc{z}abinh/map/C{x}_R{y}.jpg",
    maxZoom: 6,
    minZoom: 2,
    tileSize: 65,
    zoomOffset: 10,
    defaultZoom: 2,
  },
  binhthanh_p2: {
    path: "binhthanh_p2",
    scheme: "binhc{z}abinh/map/C{x}_R{y}.2jpg",
    maxZoom: 6,
    minZoom: 2,
    tileSize: 65,
    zoomOffset: 10,
  },
  binhthanh_p3: {
    path: "binhthanh_p3",
    scheme: "binhc{z}abinh/ma3p/C{x}_R{y}.3jpg",
    maxZoom: 6,
    minZoom: 2,
    tileSize: 65,
    zoomOffset: 10,
  },
  binhthanh_p5: {
    path: "binhthanh_p5",
    scheme: "binhc{z}abinh77/map/C{x}_R{y}.jpg",
    maxZoom: 6,
    minZoom: 2,
    tileSize: 65,
    zoomOffset: 10,
  },
  binhthanh_p6: {
    path: "binhthanh_p6",
    scheme: "binhc{z}abinh/6666map/C{x}_R{y}.jpg",
    maxZoom: 5,
    minZoom: 2,
    tileSize: 65,
    zoomOffset: 10,
  },
  binhthanh_p7: {
    path: "binhthanh_p7",
    scheme: "binhc{z}abinh/p7map/C{x}_R{y}.jpg",
    maxZoom: 6,
    minZoom: 2,
    tileSize: 65,
    zoomOffset: 10,
  },
  binhthanh_p11: {
    path: "binhthanh_p11",
    scheme: "binhc{z}abinh/11map/C{x}_R{y}.jpg",
    maxZoom: 6,
    minZoom: 2,
    tileSize: 65,
    zoomOffset: 10,
  },
  binhthanh_p12: {
    path: "binhthanh_p12",
    scheme: "binhc{z}abinh12/map/C{x}_R{y}.jpg",
    maxZoom: 6,
    minZoom: 2,
    tileSize: 65,
    zoomOffset: 10,
  },
  binhthanh_p13: {
    path: "binhthanh_p13",
    scheme: "binhc{z}abinh13/map/C{x}_R{y}.jpg",
    maxZoom: 7,
    minZoom: 2,
    tileSize: 65,
    zoomOffset: 10,
    defaultZoom: 2,
  },
  binhthanh_p14: {
    path: "binhthanh_p14",
    scheme: "binhc{z}abinh/15map14/C{x}_R{y}.jpg",
    maxZoom: 6,
    minZoom: 2,
    tileSize: 65,
    zoomOffset: 10,
  },
  binhthanh_p15: {
    path: "binhthanh_p15",
    scheme: "binhc{z}abi15nh/map/C{x}_R{y}.jpg",
    maxZoom: 5,
    minZoom: 2,
    tileSize: 65,
    zoomOffset: 10,
  },
  binhthanh_p17: {
    path: "binhthanh_p17",
    scheme: "binhc{z}abinh/17/map/C{x}_R{y}.jpg",
    maxZoom: 6,
    minZoom: 2,
    tileSize: 65,
    zoomOffset: 10,
  },

  binhthanh_p19: {
    path: "binhthanh_p19",
    scheme: "binhc{z}abin19h/map/C{x}_R{y}.jpg",
    maxZoom: 6,
    minZoom: 2,
    tileSize: 65,
    zoomOffset: 10,
  },

  binhthanh_p21: {
    path: "binhthanh_p21",
    scheme: "bi21nhc{z}abinh/map/C{x}_R{y}.jpg",
    maxZoom: 6,
    minZoom: 2,
    tileSize: 65,
    zoomOffset: 10,
  },

  binhthanh_p22: {
    path: "binhthanh_p22",
    scheme: "binhc{z}abinh22/map/C{x}_R{y}.jpg",
    maxZoom: 6,
    minZoom: 2,
    tileSize: 65,
    zoomOffset: 10,
    defaultZoom: 2,
  },

  binhthanh_p24: {
    path: "binhthanh_p24",
    maxZoom: 6,
    minZoom: 2,
    tileSize: 65,
    zoomOffset: 10,
    scheme: "binhc{z}abinh23/map/C{x}_po{y}.jpg",
  },

  binhthanh_p25: {
    path: "binhthanh_p25",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 2,
    tileSize: 65,
    zoomOffset: 10,
    scheme: "binhc{z}cz/map/C{x}_po{y}.jpg",
  },

  binhthanh_p26: {
    path: "binhthanh_p26",
    maxZoom: 7,
    minZoom: 2,
    tileSize: 65,
    zoomOffset: 10,
    defaultZoom: 2,
    scheme: "binhc{z}cz/babymap/C{x}_po{y}.jpg",
  },
  binhthanh_p27: {
    path: "binhthanh_p27",
    maxZoom: 6,
    minZoom: 2,
    tileSize: 65,
    zoomOffset: 10,
    scheme: "binhc{z}cz/goodluck/C{x}_pzzzzo{y}.jpg",
  },

  binhthanh_p28: {
    path: "binhthanh_p28",
    maxZoom: 6,
    minZoom: 1,
    tileSize: 65,
    zoomOffset: 10,
    defaultZoom: 2,
    scheme: "binhc{z}cz/cvcxvvcv/C{x}_pzzzzo{y}.jpg",
  },

  govap_p1: {
    path: "govap_p1",
    defaultZoom: 2,
    maxZoom: 5,
    minZoom: 1,
    tileSize: 70,
    zoomOffset: 10,
    ext: "png",
  },

  govap_p3: {
    path: "govap_p3",
    defaultZoom: 2,
    maxZoom: 5,
    minZoom: 1,
    tileSize: 70,
    zoomOffset: 10,
    ext: "png",
  },

  govap_p4: {
    path: "govap_p4",
    defaultZoom: 2,
    maxZoom: 5,
    minZoom: 1,
    tileSize: 70,
    zoomOffset: 10,
    ext: "png",
  },
  govap_p5: {
    path: "govap_p5",
    maxZoom: 4,
    minZoom: 2,
    tileSize: 65,
    zoomOffset: 10,
    ext: `png`,
    scheme: "binhc{z}cz/acxzxcvxvz/C{x}_pzzzzo{y}.jpg",
  },

  govap_p7: {
    path: "govap_p7",
    maxZoom: 6,
    minZoom: 1,
    tileSize: 65,
    zoomOffset: 10,
    scheme: "binhcpcxzc7{z}cz/acxvxvz/C{x}_pzzzzo{y}.png",
  },

  govap_p6: {
    path: "govap_p6",
    maxZoom: 6,
    minZoom: 1,
    tileSize: 65,
    zoomOffset: 10,
    scheme: "binhcp7{z}cz/acxvxvz/C{x}_pzzzzo{y}.png",
  },

  govap_p8: {
    path: "govap_p8",
    maxZoom: 6,
    minZoom: 1,
    tileSize: 65,
    zoomOffset: 10,
  },

  govap_p9: {
    path: "govap_p9",
    maxZoom: 6,
    minZoom: 1,
    tileSize: 65,
    zoomOffset: 10,
  },

  govap_p10: {
    path: "govap_p10",
    maxZoom: 6,
    minZoom: 1,
    tileSize: 65,
    zoomOffset: 10,
  },

  govap_p11: {
    path: "govap_p11",
    maxZoom: 6,
    minZoom: 1,
    tileSize: 65,
    zoomOffset: 10,
  },

  govap_p12: {
    path: "govap_p12",
    maxZoom: 6,
    minZoom: 1,
    tileSize: 65,
    zoomOffset: 10,
  },
  govap_p13: {
    path: "govap_p13",
    maxZoom: 6,
    minZoom: 1,
    tileSize: 65,
    zoomOffset: 10,
  },

  govap_p14: {
    path: "govap_p14",
    maxZoom: 6,
    minZoom: 2,
    tileSize: 65,
    defaultZoom: 2,
    zoomOffset: 10,
    scheme: "binhc{z}cz/vcvcvc/C{x}_pzzzzo{y}.jpg",
  },

  govap_p17: {
    path: "govap_p17",
    maxZoom: 6,
    minZoom: 2,
    tileSize: 65,
    defaultZoom: 2,
    zoomOffset: 10,
    scheme: "binhc{z}cz/vcvcvc/C{x}_pzzzzo{y}.jpg",
  },

  q12_anphudong: {
    path: "q12_anphudong",
    maxZoom: 5,
    minZoom: 1,
    tileSize: 65,
    zoomOffset: 10,
    defaultZoom: 2,
    scheme: "q211xz{z}cz/112acxvxvz/C{x}_pzzzzo{y}.jpg",
  },

  tanbinh_p1: {
    path: "tanbinh_p1",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 70,
    zoomOffset: 10,
  },

  tanbinh_p2: {
    path: "tanbinh_p2",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 70,
    zoomOffset: 10,
  },

  tanbinh_p3: {
    path: "tanbinh_p3",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 70,
    zoomOffset: 10,
  },

  tanbinh_p4: {
    path: "tanbinh_p4",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 70,
    zoomOffset: 10,
  },

  tanbinh_p5: {
    path: "tanbinh_p5",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 70,
    zoomOffset: 10,
  },

  tanbinh_p6: {
    path: "tanbinh_p6",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 70,
    zoomOffset: 10,
  },

  tanbinh_p7: {
    path: "tanbinh_p7",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 70,
    zoomOffset: 10,
  },

  tanbinh_p8: {
    path: "tanbinh_p8",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 70,
    zoomOffset: 10,
  },

  tanbinh_p9: {
    path: "tanbinh_p9",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 70,
    zoomOffset: 10,
  },

  tanbinh_p10: {
    path: "tanbinh_p10",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 70,
    zoomOffset: 10,
  },

  tanbinh_p11: {
    path: "tanbinh_p11",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 70,
    zoomOffset: 10,
  },

  tanbinh_p12: {
    path: "tanbinh_p12",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 70,
    zoomOffset: 10,
  },

  tanbinh_p13: {
    path: "tanbinh_p13",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 70,
    zoomOffset: 10,
  },

  tanbinh_p14: {
    path: "tanbinh_p14",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 70,
    zoomOffset: 10,
  },

  tanbinh_p15: {
    path: "tanbinh_p15",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 70,
    zoomOffset: 10,
  },

  thuduc_hiepphu: {
    path: "thuduc_hiepphu",
    defaultZoom: 2,
    maxZoom: 5,
    minZoom: 1,
    tileSize: 60,
    zoomOffset: 10,
  },

  phunhuan_p1: {
    path: "phunhuan_p1",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 70,
    zoomOffset: 10,
  },

  phunhuan_p2: {
    path: "phunhuan_p2",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 70,
    zoomOffset: 10,
  },

  phunhuan_p3: {
    path: "phunhuan_p3",
    defaultZoom: 2,
    maxZoom: 5,
    minZoom: 1,
    tileSize: 70,
    zoomOffset: 10,
  },

  phunhuan_p4: {
    path: "phunhuan_p4",
    defaultZoom: 2,
    maxZoom: 5,
    minZoom: 1,
    tileSize: 70,
    zoomOffset: 10,
  },

  phunhuan_p5: {
    path: "phunhuan_p5",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 70,
    zoomOffset: 10,
  },

  phunhuan_p7: {
    path: "phunhuan_p7",
    defaultZoom: 2,
    maxZoom: 5,
    minZoom: 1,
    tileSize: 70,
    zoomOffset: 10,
  },

  phunhuan_p8: {
    path: "phunhuan_p8",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 70,
    zoomOffset: 10,
  },

  phunhuan_p9: {
    path: "phunhuan_p9",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 70,
    zoomOffset: 10,
  },

  phunhuan_p10: {
    path: "phunhuan_p10",
    defaultZoom: 2,
    maxZoom: 5,
    minZoom: 1,
    tileSize: 70,
    zoomOffset: 10,
  },

  phunhuan_p11: {
    path: "phunhuan_p11",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 70,
    zoomOffset: 10,
  },

  phunhuan_p13: {
    path: "phunhuan_p13",
    defaultZoom: 2,
    maxZoom: 5,
    minZoom: 1,
    tileSize: 70,
    zoomOffset: 10,
  },

  phunhuan_p15: {
    path: "phunhuan_p15",
    defaultZoom: 2,
    maxZoom: 5,
    minZoom: 1,
    tileSize: 70,
    zoomOffset: 10,
  },

  phunhuan_p17: {
    path: "phunhuan_p17",
    defaultZoom: 2,
    maxZoom: 5,
    minZoom: 2,
    tileSize: 70,
    zoomOffset: 10,
  },

  tanphu_hieptan: {
    path: "tanphu_hieptan",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 2,
    tileSize: 60,
    zoomOffset: 10,
  },
  tanphu_phuthohoa: {
    path: "tanphu_phuthohoa",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 2,
    tileSize: 255,
    zoomOffset: 10,
  },
  tanphu_hoathanh: {
    path: "tanphu_hoathanh",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 2,
    tileSize: 60,
    zoomOffset: 10,
  },
  tanphu_phuthanh: {
    path: "tanphu_phuthanh",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 60,
    zoomOffset: 10,
  },
  tanphu_phutrung: {
    path: "tanphu_phutrung",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 60,
    zoomOffset: 10,
  },
  tanphu_sonky: {
    path: "tanphu_sonky",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 60,
    zoomOffset: 10,
  },
  tanphu_tanquy: {
    path: "tanphu_tanquy",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 60,
    zoomOffset: 10,
  },
  tanphu_tansonnhi: {
    path: "tanphu_tansonnhi",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 60,
    zoomOffset: 10,
  },
  tanphu_tanthanh: {
    path: "tanphu_tanthanh",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 60,
    zoomOffset: 10,
  },
  tanphu_tanthoihoa: {
    path: "tanphu_tanthoihoa",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 60,
    zoomOffset: 10,
  },
  tanphu_taythanh: {
    path: "tanphu_taythanh",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 60,
    zoomOffset: 10,
  },

  binhtan_binhtridonga: {
    path: "binhtan_binhtridonga",
    defaultZoom: 2,
    maxZoom: 8,
    minZoom: 1,
    tileSize: 60,
    zoomOffset: 10,
  },

  quan10_p1: {
    path: "quan10_p1",
    defaultZoom: 2,
    maxZoom: 5,
    minZoom: 1,
    tileSize: 60,
    zoomOffset: 10,
  },

  quan10_p2: {
    path: "quan10_p2",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 60,
    zoomOffset: 10,
  },
  quan10_p4: {
    path: "quan10_p4",
    defaultZoom: 2,
    maxZoom: 5,
    minZoom: 1,
    tileSize: 60,
    zoomOffset: 10,
  },
  quan10_p5: {
    path: "quan10_p5",
    defaultZoom: 2,
    maxZoom: 5,
    minZoom: 1,
    tileSize: 60,
    zoomOffset: 10,
  },
  quan10_p6: {
    path: "quan10_p6",
    defaultZoom: 2,
    maxZoom: 5,
    minZoom: 1,
    tileSize: 60,
    zoomOffset: 10,
  },
  quan10_p7: {
    path: "quan10_p7",
    defaultZoom: 2,
    maxZoom: 5,
    minZoom: 1,
    tileSize: 60,
    zoomOffset: 10,
  },
  quan10_p8: {
    path: "quan10_p8",
    defaultZoom: 2,
    maxZoom: 5,
    minZoom: 1,
    tileSize: 60,
    zoomOffset: 10,
  },

  quan10_p11: {
    path: "quan10_p11",
    defaultZoom: 1,
    maxZoom: 5,
    minZoom: 1,
    tileSize: 125,
    zoomOffset: 10,
  },
  quan10_p12: {
    path: "quan10_p12",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 70,
    zoomOffset: 10,
  },
  quan10_p13: {
    path: "quan10_p13",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 70,
    zoomOffset: 10,
  },
  quan10_p14: {
    path: "quan10_p14",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 60,
    zoomOffset: 10,
  },
  quan10_p15: {
    path: "quan10_p15",
    defaultZoom: 2,
    maxZoom: 6,
    minZoom: 1,
    tileSize: 60,
    zoomOffset: 10,
  },
};
